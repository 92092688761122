<template>
  <div class="container">
    <div class="pa-20">
      <div class="flex f-center f-between">
        <img class="logo" src="~@/assets/img/logo.png" />
        <div class="flex f-center back-box" @click="$router.replace('/')">
          <img class="back" src="~@/assets/img/back.png"/>
          <span>返回</span>
        </div>
      </div>
      <div class="content">
        <div class="search flex f-center pa-t-10">
          <van-field class="input" v-model="cardNo" label="" placeholder="输入访客证号码搜索" />
          <van-button icon="search" size="normal" type="primary" color="#003AA9" @click="search">搜索</van-button>
        </div>
        <div class="form-big" v-if="topList.length">
          <div class="form flex">
          <div class="tab" v-for="(item, index) in topList" :key="index">
            <div class="name">人员{{$util.toChinesNum(index + 1)}}：{{item.name}}</div>
            <!-- <div class="flex f-center f-between pa-lr-10 ma-tb-10">
              <div class="width30 t-right">体温</div>
              <van-field readonly class="input" v-model="item.temperatureName" label="" />
            </div> -->
            <div class="flex f-center f-between pa-lr-10  ma-t-20">
              <div class="width40 t-right">访客证号码
              </div>
              <van-field readonly class="input" v-model="item.cardNo" label="" />
            </div>
          </div>
        </div>
        </div>
        <div v-else>
          <el-empty image-size="60" description="暂无数据"></el-empty>
        </div>
      </div>
      <div class="content">
        <div class="title font-bold">
          <span class="all" @click="checkAll()">全选</span>其他来访人员信息
        </div>
        <div class="form-big" v-if="otherList.length">
          <div class="form form1 flex">
          <div class="tab" :class="{
            'active' : checkList.indexOf(item.id) != -1
          }" v-for="(item, index) in otherList" :key="index" @click="check(item.id)">
            <div class="name">人员{{$util.toChinesNum(index + 1)}}：{{item.name}}</div>
            <!-- <div class="flex f-center f-between pa-lr-10 ma-tb-10">
              <div class="width30 t-right">体温
              </div>
              <van-field readonly class="input" v-model="item.temperatureName" label="" />
            </div> -->
            <div class="flex f-center f-between pa-lr-10 ma-t-20">
              <div class="width30 t-right">访客证号码
              </div>
              <van-field readonly class="input" v-model="item.cardNo" label="" />
            </div>
          </div>
        </div>
        </div>
        <div v-else>
          <el-empty image-size="60" description="暂无数据"></el-empty>
        </div>
      </div>
      <div class="width40 ma-lr-auto ma-t-20">
        <van-button type="primary" class="width100" size="normal" color="#003AA9" @click="dialogVisibleGiveBack=true">确认离场</van-button>
      </div>
    </div>

    <!-- 离场归还访客证、停车证弹框 -->
    <el-dialog v-model="dialogVisibleGiveBack" title="提示" :show-close="false" :close-on-click-modal="false">
      <div class="fon-18">
        <div>
          <div>是否归还 <span class="c-red">访客证？</span></div>
          <div class="ma-t-10">
            <el-checkbox-group v-model="formGiveBack.a" @change="giveBackChange" size="medium">
              <el-checkbox label="未归还" border ></el-checkbox>
              <template v-for="(item,index) in topList.concat(otherList)" :key="index" >
                <el-checkbox :label="item.cardNo"  border  v-if="checkList.indexOf(item.id) != -1" ></el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
        </div>
        <div class="ma-t-20" v-if="info.parkingNoList &&  info.parkingNoList.length>0">
          <div>是否归还 <span class="c-red">访客停车证？</span></div>
          <div class="ma-t-10">
            <el-checkbox-group v-model="formGiveBack.b" @change="giveBackCarChange" size="medium">
            <el-checkbox label="未归还" border></el-checkbox>
            <el-checkbox :label="item"  border  v-for="(item,index) in parkingNoList" :key="index" ></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="flex f-around ma-t-20">
          <van-button  class="width50" plain @click="closeGiveBack()">取消</van-button>
          <van-button color="#003AA9" type="primary" class="width50" @click="submit">确认</van-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
let _this;
export default {
  data() {
    return {
      info:{},//预约信息
      cardNo: '',
      checkList: [],
      otherList: [],
      parkingNoList:[],//访客证号码
      id: '',
      topList: [],
      cardType:'',
      dialogVisibleGiveBack: false,
      formGiveBack: {
        a:['未归还'],
        b:['未归还']
      }
    };
  },
  //监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    next()
  },
  created() {
    _this = this;
    _this.cardNo = _this.$route.query.cardNo
    _this.cardType = _this.$route.query.cardType
    if (_this.$route.query.cardNo || _this.$route.query.code) {
      _this.loadData();	
    }
  },
  watch: {
    $route(to, from) {
      //  window.location.reload(); //监测到路由发生跳转时刷新一次页面
   },
  },
  computed:{
    getCardNos(){
      
    }
  },
  methods: {
    // 搜索
    search() {
      if (_this.$validator.isEmpty(_this.cardNo)) {
        return _this.$toast("请输入访客证号");
      }
      let list = _this.checkList.map((item) => {
        return item.cardNo
      })
      if (list.indexOf(_this.cardNo) > -1) {
        return _this.$toast("请访客已选中");
      }
      _this.loadData('refresh')
    },

    // 加载数据
    loadData(type) {
      if (type == 'refresh') {
        _this.otherList = []
        _this.checkList = []
        _this.topList = []
      }
      let params
      if (_this.$route.query.code) {
        params = {
          code: _this.$route.query.code,
          queryType:1,
        }
      } else {
        params = {
          cardNo: _this.cardNo,
          cardType:_this.cardType,
          queryType:1,
        }
      }
      _this.$request({
        url: '/security/query',
        method: 'GET',
        params: params
      }).then((res) => {
        _this.info=res
        _this.parkingNoList=res.parkingNoList
        if (_this.$route.query.code) {
          // 扫码进入
          res.members.map((item) => {
            if(item.status == 2) {
              _this.otherList.push(item)
              _this.checkList.push(item.id)
            }
          })
        } else {
          // 搜索访客证号进入
          let index = res.members.findIndex((item) => item.cardNo == _this.cardNo)
          _this.topList.push(res.members[index])
          _this.checkList.push(res.members[index].id)
          res.members.splice(index, 1)
          res.members.map((item) => {
            if(item.status == 2 && item.cardNo != _this.cardNo) {
              _this.otherList.push(item)
            }
          })
        }
        _this.filterList()
      });
    },

    // 文字
    filterList() {
      _this.topList.map((item) => {
        item.temperatureName = item.temperature == 0 ? '正常' : '不正常'
      })
      _this.otherList.map((item) => {
        item.temperatureName = item.temperature == 0 ? '正常' : '不正常'
      })
    },

    // 选择
    check(id) {
      if (_this.checkList.indexOf(id) === -1) {
        _this.checkList.push(id)
      } else {
        _this.checkList.splice(_this.checkList.indexOf(id), 1)
      }
    },

    // 全选
    checkAll() {
      if(_this.checkList.length!=_this.otherList.length){
        _this.otherList.map((item) => {
          if (_this.checkList.indexOf(item.id) === -1) {
            _this.checkList.push(item.id)
          }
        })
      }else{
        _this.checkList=[]
      }

      
    },

    // 离场
    submit() {
      if (_this.$validator.isEmpty(_this.checkList)) {
        return _this.$toast('请选择离场人员');
      }
      _this.$request({
        url: '/security/out',
        method: 'POST',
        data: {
          id: _this.info.id,
          entranceId: _this.$store.state.entranceId,
          list: _this.checkList,
          cardNoList:_this.getCardNoList(),
          parkingNoList:_this.getParkingNoList()
        }
      }).then(() => {
        _this.$router.replace('/enter/success')
      });
    },
     //隐藏弹框
     closeGiveBack(){
      _this.dialogVisibleGiveBack=false;
      _this.formGiveBack= {
        a:['未归还'],
        b:['未归还']
      }
    },
    //访客证归还选项改变后
    giveBackChange(e){
      console.log(e)
      if(e[e.length-1]=='未归还'){
        _this.formGiveBack.a=['未归还']
      }else{
        let findex=_this.formGiveBack.a.findIndex(item=>item=='未归还')
        if(findex!=-1){
          _this.formGiveBack.a.splice(findex,1)
        }
      }
      console.log(_this.formGiveBack.a)

    },
    //停车证归还选项改变后
    giveBackCarChange(e){
      console.log(e)
      if(e[e.length-1]=='未归还'){
        _this.formGiveBack.b=['未归还']
      }else{
        let findex=_this.formGiveBack.b.findIndex(item=>item=='未归还')
        if(findex!=-1){
          _this.formGiveBack.b.splice(findex,1)
        }
      }
    },
    //获取归还的访客证号码
    getCardNoList(){
      if(_this.formGiveBack.a.indexOf('未归还')!=-1){
        return []
      }else{
        return _this.formGiveBack.a
      }
    },
    //访客证还的访客证号码
    getParkingNoList(){
      if(_this.formGiveBack.b.indexOf('未归还')!=-1){
        return []
      }else{
        return _this.formGiveBack.b
      }
    }
  },
};
</script>

<style lang="less" scoped>
.content{
  margin-top: 10px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  .title {
    position: relative;
    margin: auto;
    font-size: 16px;
    height: 40px;
    line-height: 42px;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    .all {
      position: absolute;
      left: 10px;
      font-size: 14px;
      color: rgba(0, 160, 233, 1);
    }
  }
  .form-big{
    overflow-x:scroll;
    .form {
    font-size: 12px;
    display:inline-flex;
    &.form1{
      .tab{
        border: 1px solid #BFBFBF;
        .name{
          background: #BFBFBF;
        }
      }
    }
    .tab{
      width: 260px;
      height: 135px;
      background: #FFFFFF;
      border: 1px solid #003AA9;
      border-top: none;
      border-radius: 5px;
      margin: 15px 10px 15px 0;
      &:first-child{
        margin-left: 10px;
      }
      .name{
        background: #003AA9;
        border-radius: 5px 5px 0px 0;
        height: 35px;
        line-height: 35px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
      }
      .input {
        background: #eeeeee;
        border-radius: 5px;
        padding: 5px 8px;
        width: 65%;
      }
      .star {
        color: red;
      }
      .el-select {
        width: 65%;
      }
      :deep(.el-input__inner) {
        background-color: #eeeeee !important;
        border: none;
        height: 34px;
      }
    }
  }
  }
  .active{
    &.tab{
      border: 1px solid #003AA9!important;
      .name{
        background: #003AA9!important;
      }
    }
  }
}
</style>